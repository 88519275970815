<template lang="pug">
  .Content
    Header
    form.contain(@submit.prevent="sendData" autocomplete="off" style="margin-top: 35px;").px-0
      .contain.color-colmedica-gray.pb-4.mt-0
        h2.TitleSection.text-container-title Te damos la Bienvenida
        h2.TitleSection.text-center.text-container-title a Oficina&nbspVirtual
        p.TextSection.text-center.pt-4.mt-2.mb-0.text-section-container(style="font-size: 17px;") Aquí podrás realizar con facilidad la
        p.TextSection.text-center.m-0.text-section-container(style="font-size: 17px;") solicitud de Autorizaciones Médicas sin
        p.TextSection.text-center.text-section-container(style="font-size: 17px;") desplazamientos.
        p.TextSection.text-center.mb-3.text-section-container(style="font-size: 17px; color: #212529"): strong Tenemos la opción de agendar un turno virtual para recibir atención personalizada
      .ModalContainer(v-if="showModalError")
        .Overlay
        .Modal
          header.Modal_header.py-3.p-1.d-flex.justify-content-center.align-items-center.position-relative
            .centeredInfo.d-flex.justify-content-center.align-items-center.mt-2.mb-1
              svg(width='23' height='23' viewbox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg')
                path(d='M0.85 11.5C0.85 5.61832 5.61792 0.850249 11.4995 0.85C17.3791 0.856526 22.1438 5.62136 22.15 11.5009C22.1495 17.3823 17.3815 22.15 11.5 22.15C5.61817 22.15 0.85 17.3818 0.85 11.5Z' stroke='white' stroke-width='1.7')
                path(d='M11.5013 6.71094C10.9558 6.71094 10.543 6.99787 10.543 7.46132V12.6705C10.543 13.1344 10.9557 13.4204 11.5013 13.4204C12.0335 13.4204 12.4596 13.1224 12.4596 12.6705V7.46132C12.4596 7.00887 12.0335 6.71094 11.5013 6.71094ZM11.5013 14.378C10.9772 14.378 10.5506 14.8079 10.5506 15.3366C10.5506 15.8644 10.9772 16.2943 11.5013 16.2943C12.0254 16.2943 12.4515 15.8644 12.4515 15.3366C12.4515 14.8079 12.0254 14.378 11.5013 14.378Z' fill='#F4F4F4')
              h4.m-0.ml-2.centeredInfoH4 Usuario no activo
            button(
              @click="() => onChangeShowErrorModal(false)"
            ).button-without-styles
              svg(width='20' height='21' viewbox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg').position-absolute.svgCloseIcon
                path(d='M2.49023 17.7656L17.2565 2.99936' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
                path(d='M2.49023 3.23438L17.2565 18.0006' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
          section.Modal_content.px-4.text-center
            p.mainModalText Asegurate de ingresar los datos correctos del usuario que requiere autorización
          footer.Modal_footer.px-4.pb-3.mx-2
            button(
              @click="() => onChangeShowErrorModal(false)"
            ).Modal_footer_button.py-2.px-1.w-100
              p.my-1 Ingresar nuevos datos
            a.afiliateText.mt-1(@click="goToView('Afiliate')") Afiliate a Colmédica
            //- FIXME: Queda pendiente
      p.TextSection.weight-500.text-start.mb-2 Selecciona el tipo de identificación
      .position-relative
        b-form-select.mb-4.number-input.py-2.pl-3(
          v-model="selected"
          :options="options"
          size="sm"
          required
          autocomplete="off").position-relative.selectStyles
        .position-absolute.arrowBottomStyles
          svg(width='16' height='9' viewbox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.4215 8.06455L8.42137 8.06467C8.30826 8.17102 8.15872 8.23002 8.00348 8.22956M8.4215 8.06455L15.0341 0.998593C14.9785 0.939614 14.9117 0.89231 14.8377 0.859444C14.7636 0.826576 14.6837 0.808804 14.6027 0.807168C14.5217 0.805532 14.4411 0.820065 14.3658 0.849916C14.2911 0.879507 14.223 0.923561 14.1655 0.979541L8.00364 6.78267L1.84316 0.98104C1.78648 0.923711 1.71899 0.878178 1.64457 0.847096C1.56868 0.815398 1.48716 0.799385 1.40492 0.800018C1.32268 0.800652 1.24142 0.81792 1.16603 0.850783C1.09063 0.883646 1.02267 0.931425 0.966226 0.991243C0.909783 1.05106 0.866027 1.12168 0.837593 1.19885C0.809159 1.27603 0.796634 1.35815 0.800772 1.44029C0.804911 1.52243 0.825627 1.60289 0.861674 1.67681C0.89705 1.74936 0.946474 1.81414 1.00706 1.86742L7.58526 8.06349C7.58528 8.0635 7.58529 8.06351 7.58531 8.06353C7.69833 8.17034 7.84799 8.22975 8.00348 8.22956M8.4215 8.06455L15.0015 1.86682L8.4215 8.06455ZM8.00348 8.22956C8.00327 8.22956 8.00306 8.22956 8.00285 8.22956L8.00365 8.02956L8.0041 8.22956C8.00389 8.22956 8.00368 8.22956 8.00348 8.22956Z' fill='#737373' stroke='#737373' stroke-width='0.4')
      p(
        :class="number_id && 'activeLabel'"
      ).TextSection.weight-500.text-start.mb-2 Ingresa el número de identificación del usuario que requiere la autorización
      div
        input.p-2.number-input(
          autocomplete="off"
          v-model="number_id"
          :disabled="!isCapcha"
          :placeholder="_number"
          ref="id_number"
          required)#cedula_number
        .key-board(v-if="!isCapcha")
          .row-numbers(v-for="uniqueList in listNumbers")
            button(
              type="button"
              v-for="(number) in uniqueList"
              :disabled="number === 'null'"
              :class="number!='null' ? 'number-button' : 'null-button'"
              :key="number"
              @click="number === 'null' ? null : (number == 'X' ? removeNumber() : addNumero(number))"
            ) {{ number }}
      .botton-actions-footer(v-if="number_id !== ''")
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="submit" :disabled="!!charging").w-100.py-2.mx-1
            div(v-if="!!!charging && !charging2")
              span.font-md Continuar
            .py-1(v-else)
              MiniSpinner
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import MiniSpinner from "../components/MiniSpinner";

export default {
  name: "Autenticate",
  components: {
    Header,
    Back,
    Continue,
    MiniSpinner
  },
  mounted() {
    this.captchaElement = document.querySelector(".grecaptcha-badge");
    if (this.captchaElement) this.captchaElement.classList.add("show");
    if (this.$refs.id_number) this.$refs.id_number.focus();

    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },
  beforeDestroy() {
    if (this.captchaElement) this.captchaElement.classList.remove("show");
  },
  data: () => ({
    captchaElement: null,
    debug: false,
    selected: "CC",
    number_id: "",
    listNumbers: [
      ["1", "2", "3"],
      ["4", "5", "6"],
      ["7", "8", "9"],
      ["null", "0", "X"]
    ],
    isCapcha: true,
    isntIntersection: false
  }),
  destroyed() {
    this.selected = null;
    this.number_id = "";
  },
  computed: {
    ...mapState({
      options: state => state.autoservice.tipide,
      errors: state => state.autoservice.validationErrors,
      charging: state => state.autoservice.charging,
      charging2: state => state.virtualrow.charging,
      showModalError: state => state.autoservice.showModalError,
      env: state => state.env,
      branch_id: state => state.branchInfo
    }),
    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },
    _number() {
      let number_id = this.number_id;
      if (number_id !== "No. Identificación") {
        var convertedPhone = "******" + number_id.substring(6);
        if (number_id.length <= 6) {
          return number_id.replace(/\d/g, "*");
        }
        return convertedPhone;
      }
      return number_id || "2389043";
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      captchaValidation: "autoservice/captchaValidation",
      updateB64Final: "autoservice/updateB64Final",
      authAndQueryColmedica: "autoservice/authAndQueryColmedica",
      demoValidation: "autoservice/demoValidation",
      authenticate: "virtualrow/authenticate"
    }),
    ...mapMutations({
      setState: "autoservice/setState"
    }),
    removeNumber() {
      if (this.number_id != "") {
        var data = this.number_id.substring(0, this.number_id.length - 1);
        if (data.length) this.number_id = data.toString();
        else this.number_id = "";
      }
    },
    onChangeShowErrorModal(value) {
      this.setState({
        key: "showModalError",
        value
      });
    },
    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },
    addNumero(pay) {
      if (this.number_id == "") this.number_id = pay.toString();
      else this.number_id = `${this.number_id}${pay}`;
    },
    async sendData() {
      if (!this.selected || this.charging) return;
      let toSend = { ti: this.selected, nu: this.number_id };
      this.updateB64Final(toSend);
      document.getElementById("cedula_number")?.blur();

      try {
        if (this.demoMode) return this.demoValidation(this.number_id);
        if (
          ["Colmedica", "colmedicaof", "brilliant", "colmedicacm"].includes(
            this.env.VUE_APP_COMPANY
          )
        )
          await this.authenticate({
            NumeroIdentificacion: this.number_id,
            TipoIdentificacion: this.selected,
            CodigoOficina: this.branch_id.branch_id
          });
        return this.authAndQueryColmedica(toSend);
      } catch (error) {
        console.error(error);
        this.showError = true;
      }
    }
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
  /* position: relative; */
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}

.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

.weight-500 {
  font-weight: 500;
}

/*  Keyboard  */
.key-board {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-numbers {
  margin: 4px auto;
  display: block;
  justify-content: center;
}

.number-input {
  display: block;
  font-size: 14px;
  text-align: start;
  width: 100%;
  border-radius: 10px;
  height: auto;
  font-weight: bold;
  font-size: var(--font-sm);
  background: none;
  /* background-color: var(--color-white); */
  border: 1px solid var(--color-gray);
  color: var(--color-dark);
}

.number-input:focus {
  outline: 0.1px solid black;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.number-button {
  background-color: #0057a0;
  color: white;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}

.null-button {
  user-select: none;
  background-color: white;
  color: white;
  border: none;
  font-size: 20px;
  margin: 0px 10px;
  width: 35px;
  height: 35px;
  border-radius: 9px;
}

/* Capcha config */
.capcha-contain {
  margin-top: 20px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 24px;
  left: 0;
}

.arrowBottomStyles {
  right: 10px;
  padding-right: 10px;
  top: 7px;
  background: none;
}

.selectStyles {
  z-index: 2;
  background: none;
  padding-right: 35px;
}

.Overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 53% !important;
  z-index: 201;
}

.mainModalText {
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;

  text-align: center;
  letter-spacing: -0.0749167px;

  color: var(--color-black-cm);
}

.Modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 202;
  background-color: white;
  border-radius: 20px;
  max-width: 330px;
  min-width: 320px;
}

.activeLabel {
  color: #009fe3;
}

.activeLabel + div input:focus {
  outline: 0.5px solid #3290e0;
}

.Modal_header {
  background-color: var(--color-bad);
  border-radius: 20px 20px 0 0;
}

.centeredInfoH4 {
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;

  text-align: center;
  letter-spacing: -0.408px;

  color: var(--color-white);
}

.svgCloseIcon {
  right: 20px;
  top: 20px;
}

.Modal_content {
  font-weight: 400;
  font-size: 15px;

  margin-top: 28px;
  margin-bottom: 19px;
  line-height: 17px;
}

.Modal_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal_footer_button {
  color: white;
  background-color: var(--color-secondary);
  border-radius: 19.6226px;
  border: none;
  display: block;
}

.afiliateText {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;

  color: var(--color-secondary);
}

.missedCaptcha {
  position: fixed;
  bottom: 125px;
  left: 50%;
  transform: translateX(-50%);
}

.text-container-title {
  max-width: fit-content;
  width: 100%;
  display: flex;
  text-align: justify;
  margin: 0 auto;
}
</style>
