<template lang="pug">
  .col-12.header#headerCalendarTitle
    div(style="margin: 0px auto;")
      img(class="client_logo" :src="Logo" v-openlog)
</template>
<script>
import LogoColmedica from "@/assets/colmedica_logo.png";
import { mapState } from "vuex";

export default {
  name: "Header",

  data: () => ({
    LogoColmedica
  }),

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  }
};
</script>
<style scoped>
.header {
  text-align: center;
  padding-top: 16px;
}
.client_logo {
  max-width: 170px;
  max-height: 58px;
  height: auto;
}
</style>
