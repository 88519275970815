<template lang="pug">
.Content.pt-5
  //- .botton-actions.ml-5
  //-   .d-flex.justify-content-between
  //-     button(@click="goToView('Home')" type="button").button-without-styles
  //-       svg(width='20' height='21' viewbox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg')
  //-         path(d='M2.48828 17.7656L17.2545 2.99936' stroke='#0058A2' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
  //-         path(d='M2.48828 3.23438L17.2545 18.0006' stroke='#0058A2' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
  .col-12.contain.color-colmedica-gray.mt-4
    h2.TitleSection Tu solicitud ha sido radicada exitosamente
  .px-2.mt-3
    //- ol.orderList
    //-   li(v-for="list in orderList" :key="list")
    //-     p {{ list }}
    p.TextSection.mb-0
      strong Nuestro horario de videollamada es de:
    br
    p.TextSection.mt-0 {{ showSchedule }}
    br
    p
      strong.color-colmedica-light Te responderemos tu solicitud dentro de las próximas 12 horas hábiles. 

    p.text-left.mt-0 Si deseas puedes agendar una videollamada para revisar tu solicitud con uno de nuestros asesores.

    b-button(
      variant="bluecolmedica"
      @click="() => goToView('ToSchedule')"
    ).w-100.py-2.text-center.mx-1
      .mx-auto.align-items-center.d-block
        span Agendar Videollamada

    .mx-auto.d-block.buttonBack
      b-button(variant="whitecolmedica")
        span Ya puedes cerrar esta ventana
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";

export default {
  name: "OutOperationsFinish",
  components: {
    Header
  },
  computed: {
    ...mapState({
      branchInfo: state => state.autoservice.branchInfo,
      client: state => state.autoservice.client
    }),

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + splited[1] : ""
      }`;
    },

    showSchedule() {
      return (
        this.branchInfo?.activeBranchMessage ||
        "De lunes a viernes de 7:00 am a 6:30 pm Sábados de 7:00 am a 1:00pm."
      );
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    })
  }
};
</script>
<style scoped>
.text-middle {
  margin: auto;
  width: 90%;
  margin-top: 35px;
  font-size: 17px;
  text-align: center;
  color: #00599d;
}

.orderList {
  max-width: 274px;
  margin: auto;
  margin-top: 50px;
}

.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 24px;
  right: 0;
}

.buttonBack {
  padding-bottom: 31.11px;
  font-weight: 500;
  font-size: 20.3521px;
  line-height: 27px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 340px;
  width: 100%;
  opacity: 1;
  background-color: #f4f4f4;
}
</style>
